"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.$ModalContent = exports.$ModalClose = exports.$ModalTitle = exports.$ModalHeader = exports.$ModalInner = exports.$Modal = exports.$ModalFade = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const style_1 = require("../../general/Block/style");
const constants_1 = require("../../common/constants");
function getTheme(theme) {
    return {
        fade: {
            backgroundColors: {
                primary: theme.colors.shapes.black050,
                secondary: theme.component_color.modal.fade_bg_colors.secondary,
            },
        },
        sliderColors: {
            track: 'transparent',
            normal: theme.semantic_color.scrollbar.default,
            hover: theme.semantic_color.scrollbar.hover,
        },
        backgroundColors: {
            primary: theme.semantic_color.pop_up.bg.default,
            secondary: theme.semantic_color.bg_primary.bg_page,
        },
        colors: {
            primary: theme.semantic_color.text.primary,
            secondary: theme.semantic_color.text.secondary,
        },
        header: {
            padding: {
                withClose: {
                    [constants_1.mediaSizes.s]: '12px 64px 12px 12px',
                    [constants_1.mediaSizes.m]: '24px 64px 12px 24px',
                    [constants_1.mediaSizes.l]: '24px 64px 12px 24px',
                },
                normal: {
                    [constants_1.mediaSizes.s]: '12px 12px 12px 12px',
                    [constants_1.mediaSizes.m]: '24px 24px 8px 24px',
                    [constants_1.mediaSizes.l]: '24px 24px 8px 24px',
                },
            },
        },
        content: {
            padding: {
                withClose: {
                    [constants_1.mediaSizes.s]: '12px 64px 12px 12px',
                    [constants_1.mediaSizes.m]: '12px 64px 24px 24px',
                    [constants_1.mediaSizes.l]: '12px 64px 24px 24px',
                },
                normal: {
                    [constants_1.mediaSizes.s]: '12px 12px 12px 12px',
                    [constants_1.mediaSizes.m]: '12px 24px 24px 24px',
                    [constants_1.mediaSizes.l]: '12px 24px 24px 24px',
                },
            },
        },
    };
}
function getWidth(props) {
    if (typeof props.$width === 'number')
        return `${props.$width}px`;
    return props.$width || 'auto';
}
function getMaxWidth(props) {
    if (typeof props.$maxWidth === 'number')
        return `${props.$maxWidth}px`;
    return props.$maxWidth || '100%';
}
function getMinWidth(props) {
    if (typeof props.$minWidth === 'number')
        return `${props.$minWidth}px`;
    return props.$minWidth || 'auto';
}
function getHeight(props) {
    if (typeof props.$height === 'number')
        return `${props.$height}px`;
    return props.$height || 'auto';
}
function getMaxHeight(props) {
    if (typeof props.$maxHeight === 'number')
        return `${props.$maxHeight}px`;
    return props.$maxHeight || '100%';
}
function getMinHeight(props) {
    if (typeof props.$minHeight === 'number')
        return `${props.$minHeight}px`;
    return props.$minHeight || 'auto';
}
function getHeaderPadding(props, mediaSize) {
    if (props.$withoutSpacing)
        return '0';
    if (props.$withClose)
        return getTheme(props.theme).header.padding.withClose[mediaSize];
    return getTheme(props.theme).header.padding.normal[mediaSize];
}
function getContentPadding(props, mediaSize) {
    if (props.$withoutSpacing)
        return '0';
    if (props.$withClose && !props.$withHeader)
        return getTheme(props.theme).content.padding.withClose[mediaSize];
    return getTheme(props.theme).content.padding.normal[mediaSize];
}
function getBorderRadius(props) {
    if (typeof props.$borderRadius === 'number')
        return `${props.$borderRadius}px`;
    return props.$borderRadius || 'none';
}
function getOverflow(props) {
    if (props.$visibleOverflow)
        return 'visible';
    return 'auto';
}
function getAlignment(props) {
    if (props.$align === 'center')
        return (0, styled_components_1.css) `
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `;
    if (props.$align === 'left')
        return (0, styled_components_1.css) `
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    `;
    if (props.$align === 'right')
        return (0, styled_components_1.css) `
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
    `;
}
exports.$ModalFade = styled_components_1.default.div `
  position: fixed;
  box-sizing: border-box;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${(props) => props.$zIndex};
  background: ${(props) => getTheme(props.theme).fade.backgroundColors[props.$type]};
  backdrop-filter: ${(props) => (props.$withBlur ? 'blur(8px)' : 'none')};
`;
exports.$Modal = (0, styled_components_1.default)(style_1.$Typography) `
  position: absolute;
  box-sizing: border-box;
  display: block;
  overflow-y: ${getOverflow};
  width: ${(props) => getWidth(props)};
  max-width: ${(props) => getMaxWidth(props)};
  min-width: ${(props) => getMinWidth(props)};
  height: ${(props) => getHeight(props)};
  max-height: ${(props) => getMaxHeight(props)};
  min-height: ${(props) => getMinHeight(props)};

  ${getAlignment}

  color: ${(props) => getTheme(props.theme).colors[props.$type]};
  background: ${(props) => getTheme(props.theme).backgroundColors[props.$type]};
  border-radius: ${(props) => getBorderRadius(props)};
`;
exports.$ModalInner = styled_components_1.default.div `
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
  overflow: ${getOverflow};
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => getTheme(props.theme).sliderColors.track};
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => getTheme(props.theme).sliderColors.normal};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => getTheme(props.theme).sliderColors.hover};
  }
`;
exports.$ModalHeader = styled_components_1.default.div `
  position: sticky;
  box-sizing: border-box;
  display: block;
  flex-shrink: 0;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background: ${(props) => getTheme(props.theme).backgroundColors[props.$type]};
  padding: ${(props) => getHeaderPadding(props, constants_1.mediaSizes.s)};

  @media (${constants_1.media.tablet}) {
    padding: ${(props) => getHeaderPadding(props, constants_1.mediaSizes.m)};
  }

  @media (${constants_1.media.desktop}) {
    padding: ${(props) => getHeaderPadding(props, constants_1.mediaSizes.l)};
  }
`;
exports.$ModalTitle = styled_components_1.default.div `
  position: relative;
  box-sizing: border-box;
  display: block;
`;
exports.$ModalClose = styled_components_1.default.div `
  position: absolute;
  box-sizing: border-box;
  display: block;
  top: 0;
  right: 0;
  z-index: 3;
  padding: 16px;
`;
exports.$ModalContent = styled_components_1.default.div `
  position: relative;
  box-sizing: border-box;
  display: block;
  flex-grow: 1;
  z-index: 1;
  padding: ${(props) => getContentPadding(props, constants_1.mediaSizes.s)};

  @media (${constants_1.media.tablet}) {
    padding: ${(props) => getContentPadding(props, constants_1.mediaSizes.m)};
  }

  @media (${constants_1.media.desktop}) {
    padding: ${(props) => getContentPadding(props, constants_1.mediaSizes.l)};
  }
`;
